:root {
    --background-color-light: #fff;
    --text-color-light: #333;

    --background-color-dark: #000;
    --text-color-dark: #ccc;
}

body.light-theme {
    --background-color: var(--background-color-light);
    --text-color: var(--text-color-light);
    --border-color: var(--text-color-light);
}

body.dark-theme {
    --background-color: var(--background-color-dark);
    --text-color: var(--text-color-dark);
    --border-color: var(--text-color-dark);
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    font-size: 16pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    min-height: 100vh;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}