#homeLayout2 {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .cover-photo {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
      object-fit: cover;
    }
  }

  .instance-details {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .box {
      position: static !important;
      transform: none;
      margin-left: auto;
      margin-right: auto;
      padding: 60px;
      max-width: 620px;
      text-align: center;
      @media(max-width: 600px) {
        padding: 0;
        max-width: 90vw;
      }

      h1 {
        text-align: center;
        display: inline-block;
        font-size: 37px;
        line-height: 1em;
        margin: 0 0 22px;
        font-weight: 700;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .1em;
        color: #333;

        @media(max-width: 600px) {
          font-size: 24px;
        }
      }

      h2 {
        //font-family: minion-pro;
        font-size: 19px;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .28em;
        line-height: 1.7em;
        color: #333;
        margin: 0 0 36px;
      }

      .goto-website-link {
        text-rendering: optimizeLegibility;
        white-space: nowrap;
        //font-family: proxima-nova;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 0;
        text-align: center;
        padding: 1em 1.44em;
        text-decoration: none;
        background-color: transparent;
        color: #333;
        border: 2px solid #333;
        display: inline-block;
        height: calc(22px + 2em);
        transition: background-color 170ms ease-in-out, color 170ms ease-in-out;

        span {
          display: inline-block;
          vertical-align: top;
          line-height: 22px;
          font-size: 14px;
          font-weight: 500;
        }

        svg {
          margin-left: 5px;
          font-size: 22px;
        }

        &:hover {
          background-color: black;
          color: white;
        }
      }
    }
  }
}