@import "../variables.scss";
@import "../mixins.scss";
html:has(#photoViewer) {
  overflow: hidden;
}
#publicPage {
  margin: 0 auto;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 15px;

  #page {
    width: 100%;
    overflow: hidden;
    position: relative;
    flex: 1;

    @media(max-width: $sidebar_mobile_breakpoint) {
      flex-direction: column;
    }

    #sidebarButton {
      cursor: pointer;
      z-index: 998;
      position: relative;
      width: 30px;
      height: 30px;

      > svg {
        width: 30px;
        height: 30px;
      }
    }

    #sidebar {
      padding-left: 15px;
      padding-top: 15px;
      height: 100vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: fixed;

      @media(max-width: $sidebar_mobile_breakpoint) {
        display: none;
      }

      &.sidebar-open {
        @include sb_slide_negative_left_to_right();
      }

      &.sidebar-closed {
        @include sb_slide_right_to_negative_left();
      }
    }

    #sidebar-mobile {
      padding-left: 50px;
      width: 100%;
      overflow-y: auto;
      display: none;
      flex-direction: column;
      height: $sidebar_mobile_height;
      border-bottom: 1px solid var(--border-color);

      @media(max-width: $sidebar_mobile_breakpoint) {
        display: flex;
      }

      @keyframes slideDown {
        from {
          margin-top: calc(-1 * $sidebar_mobile_height - 50px);
        }
        to {
          margin-top: 0;
        }
      }

      &.sidebar-open {
        margin-top: 0;
        transition: 0.5s;
        animation: ease-in;
        animation-name: slideDown;
      }

      @keyframes slideUp {
        from {
          margin-top: 0;
        }
        to {
          margin-top: calc(-1 * $sidebar_mobile_height - 50px);
        }
      }

      &.sidebar-closed {
        margin-top: calc(-1 * $sidebar_mobile_height - 50px);
        transition: 0.5s;
        animation: ease-in;
        animation-name: slideUp
      }
    }

    .sidebar {
      a.nav-link {
        width: 100%;
        font-weight: 400;
        line-height: 1.4em;
        color: var(--text-color);
        text-decoration: none;
        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;

        svg {
          height: 19px;
          width: 24px;
        }

        &:hover,
        &.active {
          font-weight: 600;
        }

        &:visited,
        &:hover {
          color: var(--text-color);
        }
      }
    }

    #page-content {
      padding-top: 20px;
      display: flex;
      width: 100%;

      @media (max-width: $sidebar_mobile_breakpoint) {
        padding-top: 45px;
      }

      .page-outlet {
        transform: translateX(100vw);

        @media (max-width: $sidebar_mobile_breakpoint) {
          transform: translateX(0);
          margin-top: 50px;
        }
      }

      &.mounted {
        .page-outlet {
          @include sb_slide_right_to_left();
        }
      }

      &.sidebar-closed {
        @include sb_slide_right_to_left();
        @media (max-width: $sidebar_mobile_breakpoint) {
          animation: none;
          transform: translateX(0);
        }
      }

      &.sidebar-open {
        @include sb_slide_left_to_right();
        @media (max-width: $sidebar_mobile_breakpoint) {
          animation: none;
          transform: translateX(0);
        }
      }

      .page-title {
        margin-bottom: 15px;

        .title {
          font-size: 2.4rem;
          margin-bottom: 15px;
        }

        .extra-info {
          font-size: 1.2rem;
          color: var(--text-color);
          font-style: italic;
        }
      }

      .photo-grid-layout {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        row-gap: calc(4em + 30px);
        column-gap: 30px;
        margin-bottom: 4em;

        &.photo-grid-layout--no-details {
          row-gap: 30px;
        }

        //@media (max-width: 1920px) {
        //  grid-template-columns: repeat(4, 1fr);
        //}
        //@media (max-width: 1280px) {
        //  grid-template-columns: repeat(4, 1fr);
        //}
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }

        .grid-cell {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          position: relative;
          cursor: pointer;

          &:hover {
            //transform: scale(1.05);
          }

          .thumbnail {
            width: 100%;
            position: relative;
            cursor: pointer;

            img {
              width: 100%;
            }

            .zoom-button {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              background-color:rgba(0,0,0, 0);
              transition: background-color 0.6s;

              &:hover {
                background-color:rgba(0,0,0, 0.35);

                svg {
                  transform: scale(1.4);
                  color: rgba(255, 255, 255, 0.7);
                }
              }

              svg {
                margin: 0 auto;
                width: 20%;
                height: 20%;
                color: rgba(255, 255, 255, 0.2);
                transition: transform 0.6s, color 0.6s;
              }
            }

            &.thumbnail--no-img {
              width: 100%;
              text-align: center;
              height: 100%;
              padding-top: calc(50% - 75px);

              > svg {
                width: 75px;
                height: 75px;
                color: $accentColor;
              }
            }
          }

          .details {
            position: absolute;
            bottom: calc(-4em + 5px);
            display: flex;
            flex-direction: column;
            height: 4em;
            width: 80%;
            margin: 0 auto;
            text-align: center;

            .name {
              margin-top: 15px;
            }
          }
        }
      }
    }

    .error-box {
      padding: 0 30px;
      height: 60px;
      line-height: 60px;
      background: lighten(red, 46);
      border: 2px solid crimson;
      border-radius: $borderRadius;
      color: crimson;
      font-size: 1.2rem;
      margin: 15px auto 0;
    }

    #page-loader {
      position: absolute;
      padding-left: 50%;
      padding-top: 120px;

      > span > span {
        background-color: $accentColor !important;
      }
    }
  }
}

