@import "./variables";

@keyframes sb_slide_negative_left_to_right {
  from {
    transform: translateX(-$sidebar_width_1920);
    @media(max-width: 1920px) {
      transform: translateX(-$sidebar_width_1024_1920);
    }
    @media(max-width: 1024px) {
      transform: translateX(-$sidebar_width_768_1024);
    }
    @media(max-width: 768px) {
      transform: translateX(-$sidebar_width_480_768);
    }
  }

  to {
    transform: translateX(0);
  }
}

@mixin sb_slide_negative_left_to_right() {
  transition: 0.6s;
  animation: ease-out;
  animation-name: sb_slide_negative_left_to_right;
  transform: translateX(0);
}


@keyframes sb_slide_right_to_negative_left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-$sidebar_width_1920);
    @media(max-width: 1920px) {
      transform: translateX(-$sidebar_width_1024_1920);
    }
    @media(max-width: 1024px) {
      transform: translateX(-$sidebar_width_768_1024);
    }
    @media(max-width: 768px) {
      transform: translateX(-$sidebar_width_480_768);
    }
  }
}

@mixin sb_slide_right_to_negative_left() {
  transition: 0.6s;
  animation: ease-out;
  animation-name: sb_slide_right_to_negative_left;

  transform: translateX(-1 * $sidebar_width_1920);
  @media(max-width: 1920px) {
    transform: translateX(-1 * $sidebar_width_1024_1920);
  }
  @media(max-width: 1024px) {
    transform: translateX(-1 * $sidebar_width_768_1024);
  }
  @media(max-width: 768px) {
    transform: translateX(-1 * $sidebar_width_480_768);
  }
}

@keyframes sb_slide_left_to_right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX($sidebar_width_1920);
    @media(max-width: 1920px) {
      transform: translateX($sidebar_width_1024_1920);
    }
    @media(max-width: 1024px) {
      transform: translateX($sidebar_width_768_1024);
    }
    @media(max-width: 768px) {
      transform: translateX($sidebar_width_480_768);
    }
  }
}

@mixin sb_slide_left_to_right() {
  transition: 0.6s;
  animation: ease-out;
  animation-name: sb_slide_left_to_right;

  transform: translateX($sidebar_width_1920);
  @media(max-width: 1920px) {
    transform: translateX($sidebar_width_1024_1920);
  }
  @media(max-width: 1024px) {
    transform: translateX($sidebar_width_768_1024);
  }
  @media(max-width: 768px) {
    transform: translateX($sidebar_width_480_768);
  }
}


@keyframes sb_slide_right_to_left {
  from {
    transform: translateX($sidebar_width_1920);
    @media(max-width: 1920px) {
      transform: translateX($sidebar_width_1024_1920);
    }
    @media(max-width: 1024px) {
      transform: translateX($sidebar_width_768_1024);
    }
    @media(max-width: 768px) {
      transform: translateX($sidebar_width_480_768);
    }
  }

  to {
    transform: translateX(0);
  }
}

@mixin sb_slide_right_to_left() {
  transition: 0.6s;
  animation: ease-out;
  animation-name: sb_slide_right_to_left;

  transform: translateX(0);
}