
#photoViewer {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: stretch;

  .photo-slider {
    overflow: hidden;
    width: 100%;
    position: relative;

    .photo-container {
      display: flex;
      width: 100%;
    }

    .photo {
      min-width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 90vh;
        max-width: calc(100vw - 300px);
        @media (max-width: 768px) {
          max-width: calc(100vw - 128px);
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    svg {
      color: white;
      opacity: 0.5;
      font-size: 30px;
    }
  }
}